import React from "react";
import { Link, Element } from "react-scroll";

// Importa las imágenes desde la carpeta 'src/images'
import logo from './images/Logo.png';
import missionImage from './images/Derecha.jpg';
import visionImage from './images/Izquierda.jpg';

function App() {
  return (
    <div className="font-sans">
      {/* Navbar */}
      <nav className="bg-gray-800 text-white py-4 sticky top-0 z-50">
        <ul className="flex justify-center space-x-8">
          <li>
            <Link
              to="home"
              smooth={true}
              duration={800}
              className="hover:text-gray-400 cursor-pointer"
            >
              Inicio
            </Link>
          </li>
          <li>
            <Link
              to="mission"
              smooth={true}
              duration={800}
              className="hover:text-gray-400 cursor-pointer"
            >
              Misión
            </Link>
          </li>
          <li>
            <Link
              to="vision"
              smooth={true}
              duration={800}
              className="hover:text-gray-400 cursor-pointer"
            >
              Visión
            </Link>
          </li>
          <li>
            <Link
              to="services"
              smooth={true}
              duration={800}
              className="hover:text-gray-400 cursor-pointer"
            >
              Servicios
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              duration={800}
              className="hover:text-gray-400 cursor-pointer"
            >
              Contacto
            </Link>
          </li>
        </ul>
      </nav>

      {/* Logo Section */}
      <Element name="home" className="element">
        <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-center py-16 flex items-center justify-center h-screen pb-20">
          <div className="flex flex-col items-center justify-center">
            {/* Coloca el logo aquí */}
            <img src={logo} alt="Logo de la Empresa" className="w-40 mb-4" />
            <h1 className="text-5xl font-bold mb-4">Bienvenidos a Inova Optimize</h1>
            <p className="text-xl">Transformando negocios con soluciones digitales</p>
          </div>
        </section>
      </Element>


      {/* Sección de Misión */}
      <Element name="mission" className="element">
        <section className="bg-white py-24 h-auto flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center px-8">
            <div className="md:w-1/2 text-left mr-10">
              <h2 className="text-4xl font-bold mb-4">Nuestra Misión</h2>
              <p className="text-lg text-gray-700 text-justify">
                Impulsar a empresas en crecimiento hacia la transformación digital, proporcionándoles soluciones innovadoras para la automatización de procesos. Nos comprometemos a optimizar sus operaciones con el fin de ahorrar tiempo y recursos, permitiendo que se enfoquen en sus objetivos estratégicos. A través de un enfoque personalizado y eficiente, ayudamos a nuestros clientes a mejorar su competitividad y a adaptarse a las demandas de un mercado que se encuentra en constante evolución.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8 mt-8 md:mt-0">
              <img
                src={missionImage}
                alt="Imagen Misión"
                className="rounded-lg shadow-lg w-[75%] ml-10"
              />
            </div>
          </div>
        </section>
      </Element>

      {/* Sección de Visión */}
      <Element name="vision" className="element">
        <section className="bg-gray-100 py-24 h-auto flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center px-8">
            <div className="md:w-1/2 md:pr-8 mt-8 md:mt-0">
              <img
                src={visionImage}
                alt="Imagen Visión"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-1/2 text-left text-justify">
              <h2 className="text-4xl font-bold mb-4">Nuestra Visión</h2>
              <p className="text-lg text-gray-700">
                Ser el socio estratégico de confianza para empresas que buscan transformar y optimizar sus procesos en el área de desarrollo de software, impulsando su crecimiento a través de soluciones innovadoras y eficientes.
              </p>
            </div>
          </div>
        </section>
      </Element>

      {/* Sección de Servicios */}
      <Element name="services" className="element">
        <section className="bg-white py-24 h-auto text-center flex items-center justify-center">
          <div>
            <h2 className="text-4xl font-bold mb-12">Nuestros Servicios</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-8">
              <div className="bg-gray-100 p-6 shadow-lg rounded-lg">
                <h3 className="text-2xl font-semibold mb-4">Análisis de Procedimientos</h3>
                <p className="text-gray-700">
                  Realizamos un análisis profundo de los procedimientos actuales de la empresa para identificar oportunidades de optimización y mejora.
                </p>
              </div>
              <div className="bg-gray-100 p-6 shadow-lg rounded-lg">
                <h3 className="text-2xl font-semibold mb-4">Retroalimentación y Propuestas</h3>
                <p className="text-gray-700">
                  Ofrecemos retroalimentación y propuestas que buscan reducir el uso de recursos y mejorar la eficiencia operativa.
                </p>
              </div>
              <div className="bg-gray-100 p-6 shadow-lg rounded-lg">
                <h3 className="text-2xl font-semibold mb-4">Consultorías de Optimización</h3>
                <p className="text-gray-700">
                  Brindamos consultorías especializadas en la optimización de procesos para asegurar el crecimiento y la adaptación de las empresas.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* Sección de Contacto */}
      <Element name="contact" className="element">
        <section className="bg-gray-100 py-24 h-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Datos de Contacto</h2>
          <div className="text-lg text-gray-700">
            <p>Email 1: <a href="mailto:aaronreyes@inovaoptimize.com" className="text-blue-500 hover:underline">aaronreyes@inovaoptimize.com</a></p>
            <p>Email 2: <a href="mailto:aaronreyes@inovaoptimize.com" className="text-blue-500 hover:underline">josue@inovaoptimize.com</a></p>
            <p>Email 3: <a href="mailto:aaronreyes@inovaoptimize.com" className="text-blue-500 hover:underline">chris@inovaoptimize.com</a></p>
          </div>
        </section>
      </Element>

      {/* Footer */}
      <footer className="bg-gray-800 text-white text-center py-4">
        <p>&copy; 2024 Inova Optimize. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
